<template>
  <div class="tableList">
    <el-table :data="tableDatas">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="logisticsCode" label="物流单编号"></el-table-column>
      <el-table-column prop="holdCode" label="入库单编码" width="90"></el-table-column>
      <el-table-column prop="companyName" label="物流公司"></el-table-column>
      <el-table-column prop="commodity" label="商品分类" min-width="80"></el-table-column>
      <el-table-column prop="specifications" label="商品属性" min-width="80">
         <template slot-scope="scope">
              <span>{{ scope.row.specifications | firstAttributeTransformate }}</span>
            </template>
      </el-table-column>
      <el-table-column prop="weight" label="重量" min-width="70">
           <template slot-scope="scope">
              {{ scope.row.weight }}{{scope.row.weight!=null?scope.row.unit:''}}
        </template>
      </el-table-column>
      <el-table-column prop="storageName" label="交收仓库"></el-table-column>
      <el-table-column prop="consignee" label="收货人" min-width="65"></el-table-column>
      <el-table-column prop="phone" label="收货电话"></el-table-column>
      <el-table-column prop="address" label="收货地址"></el-table-column>
      <el-table-column prop="dirver" label="指派司机"></el-table-column>
      <el-table-column prop="carCode" label="指派车辆"></el-table-column>
      <el-table-column prop="logisticsStatus" label="状态" min-width="60">
        <template slot-scope="scope">
          <span>{{ scope.row.logisticsStatus | pickupGoodsStatusFormate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" :disabled="scope.row.logisticsStatus != 0 ? true : false" @click="showAlterDialog(scope.row)" style="width: 90px!important;margin-bottom: 5px!important;">修改</el-button>
          <el-button v-if="right.confirmReceive" :disabled="scope.row.logisticsStatus == 3||scope.row.logisticsStatus == 0||scope.row.logisticsStatus == 1" type="danger" size="mini" @click="confirmReceive(scope.row.deliveryId)"  style="width: 90px!important;margin-bottom: 5px!important;margin-left: 0!important">确认收货</el-button>
        </template>
      </el-table-column>
    </el-table>
    <alertMessage ref="alter" @upDate="upData"></alertMessage>
  </div>
</template>
<script>
import alertMessage from "./components/alertMessage";

export default {
  props: {
    tableDatas: Array,
    default: function() {
      return []
    }
  },
  components: {
    alertMessage
  },
  data() {
    return {
      right: {
        confirmReceive: false
      }
    }
  },
  mounted() {
    this.getRights();
    console.log(111,this.tableDatas)
     for (let i = 0; i < this.tableDatas.length; i++) {
      if (!this.tableDatas[i].holdCode) {
        this.tableDatas[i].holdCode = '---'
      }
    }
  },
  methods: {
    upData() {
    console.log('数据更新')
      this.$emit("reLoad");
    },
    // 修改信息
    showAlterDialog(id) {
      this.$refs.alter.show(id);
    },
    getRights() {
      this.loadRight().then((loadResult) => {
        if (loadResult == 'loadRightSuccess') {
          this.right.confirmReceive = this.isHasRight('tc-trade-confirmReceipt');
        }
      })
    },
    confirmReceive(id) {
      this.$confirm('确认收货?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        protocolFwd.param_confirmReceipt.param.deliveryId = id;
        http.postFront(protocolFwd.param_confirmReceipt).then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.$EL_MESSAGE(message);
            this.$emit('reLoad');
          } else {
            this.$EL_MESSAGE(message);
          }
        });
      }).catch(() => {
                
      });
    }
  }
}
</script>
<style scoped lang="scss">
.tableList {
  margin: 10px 0;
  /deep/ .el-table thead th{
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox{
    line-height: 30px;
    margin-left: 20px;
  }
}
</style>