<template>
  <div class="subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>物流查询</p>
      </div>
    </div>
    <generalLogisticsBill :tableDatas="tableDatas" @reLoad="getDatas()"  @upData="getDatas()"/>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import generalLogisticsBill from "../../../base/generalLogisticsBill/generalLogisticsBill";
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: []
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  components: {
    generalLogisticsBill
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_queryLogisticsPickUp.param.page = this.current - 1;
      protocolFwd.param_queryLogisticsPickUp.param.size = this.pageSize;
      protocolFwd.param_queryLogisticsPickUp.param.buyFirmId = this.sessionInfoGetter.firmId;
      protocolFwd.param_queryLogisticsPickUp.param.firmId = this.sessionInfoGetter.firmId;
      protocolFwd.param_queryLogisticsPickUp.param.sellFirmId = null;
      protocolFwd.param_queryLogisticsPickUp.param.typeInt = 0;
      http.postFront(protocolFwd.param_queryLogisticsPickUp).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableDatas = value.content;
          this.totalPage = value.totalElements;
    } else {
          this.$EL_MESSAGE(message);
        }
      });
    }
  }
};
</script>
<style scoped>
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.tableList {
  margin-top: 20px;
}
.deliveryContent {
  line-height: 30px;
}
.subPage .pagination {
  margin-top: 15px;
}
</style>
