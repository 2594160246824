<template>
  <el-dialog
    v-dialogDrag
    title="修改信息"
    :visible.sync="dialogVisible"
    width="540px"
    :before-close="handleClose"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-row>
        <el-col :span="24">
          <el-form-item label="收货人" prop="consignee">
            <el-input
              v-model="form.consignee"
              placeholder="请输入提货人"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="提货人电话" prop="phone">
            <el-input
              v-model="form.phone"
              placeholder="请输入提货人电话"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="提货地址" prop="address">
            <el-input
              v-model="form.address"
              placeholder="请输入提货地址"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- <el-input v-model="carCode" placeholder="请输入内容"></el-input> -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    // 手机号
    var validateNum = (rele, value, callback) => {
      let phone = this.form.phone;
      if (value === "") {
        callback(new Error("请输入联系方式"));
      } else if (!/^1[3456789]\d{9}$/.test(phone)) {
        // this.$refs.appealForm.validateField("phoneNum");
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    // 正整数
    var Num = (rele, value, callback) => {
      if (!/^[1-9]+[0-9]*$/.test(value)) {
        // this.$refs.appealForm.validateField("phoneNum");
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      id: null,
      data: null,
      form: {
        phone: "",
        consignee: "",
        address: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入提货人电话", trigger: "change" },
          {
            validator: validateNum,
            message: "手机号格式错误",
            trigger: "blur",
          },
        ],
        consignee: [
          { required: true, message: "请输入提货人", trigger: "change" },
        ],
        address: [
          { required: true, message: "请输入提货地址", trigger: "change" },
        ]
      },
    };
  },
  methods: {
    save() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          protocolFwd.logisticsMessageAlert.param = this.form;
          protocolFwd.logisticsMessageAlert.param.deliveryId = this.data.deliveryId;
          http.postFront(protocolFwd.logisticsMessageAlert).then((response) => {
            let { value, code, message } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE("修改成功");
              this.dialogVisible = false;
              this.$emit("upDate");
            }
          });
        }
      });
    },
    show(id) {
      console.log("子组件", id);
      this.dialogVisible = true;
      this.data = id;
      this.form.phone = id.phone;
      this.form.consignee = id.consignee;
      this.form.address = id.address;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          (this.form = {
            phone: "",
            consignee: "",
            address: "",
          }),
            done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-date-editor {
  width: 100%;
}
</style>